import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { GeoLocation, GeoLocationMetaData } from 'types/User/Cookie'
import { getGeoLocation, getGeoLocationMetaData } from 'selectors/user/cookie'

export type ConnectorProps = {
  geoLocation: GeoLocation
  geoLocationMetaData: GeoLocationMetaData
}

const mapStateToProps = () =>
  createStructuredSelector({
    geoLocation: getGeoLocation,
    geoLocationMetaData: getGeoLocationMetaData,
  })

const connector = connect(mapStateToProps, null, null, { forwardRef: true })

export default connector
